import React from "react";

const AboutMe = () => {
  return (
    <section className="App-section" id="about-me">
      <h2 className="item-to-slide-up">About me</h2>
      <a href="https://www.linkedin.com/in/i-cheng/" className="profile-pic">
        <img
          src={require("../images/SuitSquare.jpg")}
          alt="Ivan Cheng"
          width="300"
          height="300"
          className="item-to-slide-up"
        />
      </a>
      <p className="item-to-slide-up">
        I first discovered my passion and aptitude for programming when I used{" "}
        <a href="https://www.autohotkey.com/">AutoHotkey</a> to code shortcuts
        and macros in my previous role as a sound engineer. I then began
        learning Python in my free time, and loved coding so much that I
        re-evaluated my career path. I then dedicated ten months to
        self-teaching programming, and began working with{" "}
        <a href="https://www.sony.co.uk/">Sony</a> in 2021 as a Software
        Developer.
      </p>

      <p className="item-to-slide-up">
        My background is in music, I graduated from University of Surrey with a
        First-Class Honours degree in{" "}
        <a href="https://www.surrey.ac.uk/undergraduate/music-and-sound-recording-tonmeister">
          Music and Sound Recording (Tonmeister)
        </a>
        . I'm currently working for <a href="https://www.sony.co.uk/">Sony</a>{" "}
        as a Software Engineer.
      </p>
    </section>
  );
};

export default AboutMe;
