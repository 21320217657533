import React, { Component } from "react";
import "./App.css";
import MyNavbar from "./components/MyNavbar";
// import { BrowserRouter as Router } from "react-router-dom";
import ContactMe from "./components/ContactMe";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import AboutMe from "./components/AboutMe";
import Header from "./components/Header";

class App extends Component {
  componentDidMount() {
    let prevScrollpos = window.pageYOffset;

    window.addEventListener("scroll", this.onWindowScroll);
    window.addEventListener("scroll", this.navbarScroll(prevScrollpos));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onWindowScroll);
    window.removeEventListener("scroll", this.navbarScroll);
  }

  onWindowScroll = () => {
    // for indicating navbar section
    const nav_sections = document.getElementsByTagName("section");
    this.sectionActive(nav_sections);

    const itemsToSlideUp = document.getElementsByClassName("item-to-slide-up");
    this.itemActive(itemsToSlideUp, "slide-up");

    const itemsToSlideLeft = document.getElementsByClassName(
      "item-to-slide-left"
    );
    this.itemActive(itemsToSlideLeft, "slide-left");
  };

  // show / hide navbar when scrolling
  navbarScroll = (prevScrollpos) => {
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      if (window.scrollY < 50) {
        // top of page
        document.getElementById("navbar").style.top = "0";
      } else if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar").style.top = "0";
      } else {
        document.getElementById("navbar").style.top = "-80px";
      }
      prevScrollpos = currentScrollPos;
    };
  };

  // is section Active? for navbar
  sectionActive = (sections) => {
    for (const section of sections) {
      const sectionTitle = section.getAttribute("id");
      const navActive = document.querySelector(
        `nav ul li a[href="/#${sectionTitle}"]`
      );

      if (this.itemInView(section)) {
        navActive.classList.add("active");
      } else {
        navActive.classList.remove("active");
      }
    }
  };

  // is the item in view?
  itemInView = (item) => {
    let bounding = item.getBoundingClientRect();
    let offset = bounding.height * 0.42;
    if (window.scrollY <= 100) {
      return false;
    }
    return (
      bounding.top + offset <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.bottom - offset >= 0
    );
  };

  // active items - add class name
  itemActive = (items, animationClassName) => {
    for (const item of items) {
      if (this.itemInView(item)) {
        item.classList.add(animationClassName);
      }
    }
  };

  render() {
    return (
      <>
        {/* <Router> */}
          <MyNavbar />
          <div className="App">
            <Header />
            <AboutMe />
            <Projects />
            <Experience />
            <ContactMe />
          </div>
        {/* </Router> */}
      </>
    );
  }
}

export default App;
