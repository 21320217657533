import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { GrLinkedin } from "react-icons/gr";
import { FaGithub } from "react-icons/fa";
import "../App.css";

const ContactMe = () => {
  return (
    <section className="App-section" id="contact-me">
      <h2 className="item-to-slide-up">Contact me</h2>
      <div className="Icons-row item-to-slide-up">
        <a href="mailto: ivan@ivancheng.co.uk" className="Icon-link">
          <AiOutlineMail size="1.7em" />
        </a>

        <a href="https://www.linkedin.com/in/i-cheng/" className="Icon-link">
          <GrLinkedin size="1.7em" />
        </a>

        <a href="https://github.com/IvanCheng1" className="Icon-link">
          <FaGithub size="1.7em" />
        </a>
      </div>
    </section>
  );
};

export default ContactMe;
