import React from "react";

const Burger = (props) => {
  // console.log(props.isOpen)
  const { isOpen, onclickHamburger } = props;
  return (
    <div
      className={isOpen ? "burger open" : "burger"}
      onClick={() => onclickHamburger()}
    >
      <div />
      <div />
      <div />
    </div>
  );
};

export default Burger;
