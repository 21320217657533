import React, { Component } from "react";
import Timeline from "@material-ui/lab/Timeline";
import { experience } from "../other/data";
import ExperienceCard from "./ExperienceCard";

class Experience extends Component {
  state = {
    experience: [],
  };

  componentDidMount() {
    this.setState({
      experience: experience,
    });
  }

  render() {
    const { experience } = this.state;

    return (
      <section className="App-section" id="experience">
        <h2 className="item-to-slide-up">Experience</h2>
        <Timeline align="left" className="timeline">
          {experience &&
            experience.map((item) => (
              <ExperienceCard item={item} key={item.subtitle} />
            ))}
        </Timeline>
      </section>
    );
  }
}

export default Experience;
