import React from "react";

const Header = () => {
  return (
    <header className="App-header" id="home">
      <div className="home-container">
        <h1>Ivan Cheng</h1>
        <h3>Software Engineer at Sony</h3>
        <p>
          {/* I'm a former Sound Engineer based in London, looking to start a career
          in software development. */}
          {/* I am a former Sound Engineer based in London, with experience in JavaScript, React, and TypeScript. */}
          {/* I am a former Sound Engineer based in London, currently working for Sony as a Software Developer. */}
        </p>
      </div>
    </header>
  );
};

export default Header;
