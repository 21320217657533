import React from "react";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const ExperienceCard = (props) => {
  const { item } = props;

  return (
    <div className="timeline-item item-to-slide-left">
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            {item.date}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          {item.type === "education" ? (
            <TimelineDot color="inherit" className="timeline-dot" />
          ) : (
            <TimelineDot
              color="inherit"
              variant="outlined"
              className="timeline-dot-outline"
            />
          )}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className="paper">
            <a href={item.link}>
              <Typography
                variant="h6"
                component="h1"
                className="timeline-item-title"
              >
                {item.title}
              </Typography>

              <Typography>{item.subtitle}</Typography>

              {item.notes.length > 0 &&
                item.notes.map((note) => (
                  <Typography variant="body2" color="textSecondary" key={note}>
                    {note}
                  </Typography>
                ))}
            </a>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </div>
  );
};

export default ExperienceCard;
