import React, { Component } from "react";
import { projects } from "../other/data";
import ProjectNav from "./ProjectNav";
import ProjectCard from "./ProjectCard";

class Projects extends Component {
  state = {
    activeTab: "",
    projects: {},
  };

  componentDidMount() {
    const activeTab = Object.keys(projects)[0];
    this.setState({
      projects,
      activeTab,
    });
  }

  handleClick = (e) => {
    const value = e.target.value;
    this.setState({
      activeTab: value,
    });
  };

  render() {
    const { activeTab, projects } = this.state;
    const currentProject = projects[activeTab];

    return (
      <section className="App-section" id="projects">
        <h2 className="item-to-slide-up">Recent Projects</h2>
        <div className="project-sidebar-container item-to-slide-up">
          <ProjectNav
            activeTab={activeTab}
            projects={projects}
            handleClick={this.handleClick}
          />
          <ProjectCard currentProject={currentProject} />
        </div>
      </section>
    );
  }
}

export default Projects;
