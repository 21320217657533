import React, { Component } from "react";
import { FaGithub } from "react-icons/fa";

class ProjectCard extends Component {
  render() {
    const { currentProject } = this.props;

    if (!currentProject) {
      return <></>;
    }

    return (
      <div className="project-card">
        <div className="project-top-bar">
          <div className="project-title">
            <a href={currentProject.github}>{currentProject.title}</a>
          </div>
          <div className="project-date">{currentProject.date}</div>
        </div>
        <div
          className={
            currentProject.image ? "project-body" : "project-body no-picture"
          }
        >
          <div className="project-bullets">
            <ul>
              {currentProject.bullets.map((bullet) => (
                <li key={bullet}>{bullet}</li>
              ))}
            </ul>
          </div>
          <div className="project-img">
            {currentProject.image && (
              <a
                href={
                  currentProject.demo === ""
                    ? currentProject.github
                    : currentProject.demo
                }
              >
                <img
                  src={currentProject.image}
                  alt={currentProject.title}
                  width="270"
                />
              </a>
            )}
          </div>
        </div>
        <div className="project-bottom-bar">
          <div className="project-code">{currentProject.code}</div>
          <a href={currentProject.github} className="Icon-link">
            <FaGithub size="1.5em" />
          </a>
        </div>
      </div>
    );
  }
}

export default ProjectCard;
