import React, { Component } from "react";
import ProjectNavBtn from "./ProjectNavBtn";

class ProjectNav extends Component {
  render() {
    const { activeTab, projects, handleClick } = this.props;

    return (
      <nav className="projects-nav">
        <ul>
          {Object.values(projects).map((project) => (
            <ProjectNavBtn
              activeTab={activeTab}
              title={project.title}
              handleClick={handleClick}
              key={project.title}
            />
          ))}
        </ul>
      </nav>
    );
  }
}

export default ProjectNav;
