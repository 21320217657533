export const projects = {
  "Collectors App": {
    title: "Collectors App",
    date: "Oct - Nov 2020",
    bullets: [
      "An app that allows you to document your collections and items",
      "You can search for a specific item or collection by typing in a keyword",
      "Cross platform on Android and iOS ",
      "Published on Google Play Store and Apple App Store",
    ],
    code: "TypeScript, React-Native, React-Redux",
    image:
      "https://github.com/IvanCheng1/Collectors-app/blob/master/src/gifs/bothDemo.gif?raw=true",
    github: "https://github.com/IvanCheng1/Collectors-app",
    demo: "",
  },
  Reminders: {
    title: "Reminders",
    date: "Oct 2020",
    bullets: [
      "A web application that allows users to make reminders and organise them in different lists",
      "Users can edit and delete lists and reminders by double clicking on the name",
      "Reminders are saved to local storage",
    ],
    code: "React, React-Redux, TypeScript",
    image:
      "https://github.com/IvanCheng1/Reminders-app/blob/master/src/gifs/example.gif?raw=true",
    github: "https://github.com/IvanCheng1/Reminders-app",
    demo: "https://reminders-app.netlify.app/",
  },
  "My Notes": {
    title: "My Notes",
    date: "Sep 2020",
    bullets: [
      "A simple web application that allow users to quickly save their notes",
      "Users can edit and delete the note by clicking on the edit button",
    ],
    code: "React, TypeScript",
    image:
      "https://github.com/IvanCheng1/Notes-app/blob/master/src/gifs/example.gif?raw=true",
    github: "https://github.com/IvanCheng1/Notes-app",
    demo: "https://simple-notes-app.netlify.app/",
  },
  "Recipe App": {
    title: "Recipe App",
    date: "Aug 2020",
    bullets: [
      "A simple recipe app that allow users to quickly add recipe ingredients to the shopping list",
      "Add extra ingredients to shopping list",
      "Users can add recipes from the 'Add Recipe' tab",
      "Cross platform on iOS and android",
    ],
    code: "React-Native, React-Redux, JavaScript",
    image:
      "https://github.com/IvanCheng1/Recipe-app/blob/master/src/gifs/RecipeApp.gif?raw=true",
    github: "https://github.com/IvanCheng1/Recipe-app",
    demo: "",
  },
  "Flash Cards": {
    title: "Flash Cards",
    date: "Jul 2020",
    bullets: [
      "An application that allow users to study collections of flashcards",
      "Users can add and remove decks and cards, view all cards, and take quizzes on different decks",
      "Cross platform on iOS and android",
    ],
    code: "React-Native, React-Redux, JavaScript",
    image:
      "https://github.com/IvanCheng1/Flash-cards/blob/master/src/gifs/both-example.gif?raw=true",
    github: "https://github.com/IvanCheng1/Flash-cards",
    demo: "",
  },
  "Travel App": {
    title: "Travel App",
    date: "Jun 2020",
    bullets: [
      "A web application that logs future holidays and generate the relevent weather forecast",
      "Enter the city destination and the start and end date of the trip to save the holiday",
    ],
    code: "JavaScript, Node.js, Sass, Webpack",
    image:
      "https://github.com/IvanCheng1/Travel-App/blob/master/src/gifs/example.gif?raw=true",
    github: "https://github.com/IvanCheng1/Travel-App",
    demo: "https://travel-app-weather.herokuapp.com/",
  },
  "Orchestra Agency API": {
    title: "Orchestra Agency API",
    date: "Jun 2020",
    bullets: [
      "A database-backed web API to organise concerts and assign players to each concert",
      "Many-to-many relationship between two classes",
      "Auth0 for authentication and user control",
    ],
    code: "Python, Flask, SQLAlchemy, PostgreSQL",
    image: "",
    github: "https://github.com/IvanCheng1/Orchestra-Players-Agency",
    demo: "",
  },
  "Pong game": {
    title: "Pong game",
    date: "May 2020",
    bullets: [
      "A classic simple game using arrow keys to control paddle",
      "Optionally played with two players",
    ],
    code: "C++17, SDL2",
    image:
      "https://raw.githubusercontent.com/IvanCheng1/Pong-in-Cpp/master/Pong.gif",
    github: "https://github.com/IvanCheng1/Pong-in-Cpp",
    demo: "",
  },
};

// =================================================

export const experience = [
  {
    date: "July 2022 - present",
    link: "https://www.sony.co.uk/",
    title: "Sony",
    subtitle: "Engineer",
    notes: [],
    type: "employment",
  },
  {
    date: "Oct 2021 - June 2022",
    link: "https://www.sony.co.uk/",
    title: "Sony",
    subtitle: "Graduate Engineer",
    notes: [],
    type: "employment",
  },
  {
    date: "Jan 2021 - Oct 2021",
    link: "https://www.sony.co.uk/",
    title: "Sony",
    subtitle: "Software Developer",
    notes: [],
    type: "employment",
  },
  {
    date: "Aug - Sep 2020",
    link:
      "https://www.udacity.com/course/intermediate-javascript-nanodegree--nd032",
    title: "Udacity",
    subtitle: "JavaScript Developer Nanodegree",
    notes: ["Functional Programming, Asynchronous Programming"],
    type: "education",
  },
  {
    date: "Jul - Aug 2020",
    link: "https://www.udacity.com/course/react-nanodegree--nd019",
    title: "Udacity",
    subtitle: "React Developer Nanodegree",
    notes: ["React, React-Redux, React-Native"],
    type: "education",
  },
  {
    date: "Jun - Jul 2020",
    link:
      "https://www.udacity.com/course/front-end-web-developer-nanodegree--nd0011",
    title: "Udacity",
    subtitle: "Front End Web Developer Nanodegree",
    notes: ["HTML, CSS, Sass, JavaScript, Webpack"],
    type: "education",
  },
  {
    date: "May - Jun 2020",
    link:
      "https://www.udacity.com/course/full-stack-web-developer-nanodegree--nd0044",
    title: "Udacity",
    subtitle: "Full-Stack Web Developer Nanodegree",
    notes: ["Python, Flask, SQL, API, Docker, AWS EKS"],
    type: "education",
  },
  {
    date: "Apr - May 2020",
    link: "https://www.udacity.com/course/c-plus-plus-nanodegree--nd213",
    title: "Udacity",
    subtitle: "C++ Developer Nanodegree",
    notes: ["C++, Move Semantics, Concurrency"],
    type: "education",
  },
  {
    date: "Mar - Apr 2020",
    link:
      "https://www.udacity.com/course/data-structures-and-algorithms-nanodegree--nd256",
    title: "Udacity",
    subtitle: "Data Structures & Algorithms Nanodegree",
    notes: [
      "Queues, Stacks, Trees, Maps...",
      "Binary Search, Sorting, Greedy Algorithms...",
    ],
    type: "education",
  },
  {
    date: "Feb - Mar 2020",
    link:
      "https://www.udacity.com/course/ai-programming-python-nanodegree--nd089",
    title: "Udacity",
    subtitle: "AI Programming with Python Nanodegree",
    notes: ["Python, NumPy, PyTorch, pandas"],
    type: "education",
  },
  {
    date: "Jan - Feb 2020",
    link:
      "https://www.udacity.com/course/intro-to-programming-nanodegree--nd000",
    title: "Udacity",
    subtitle: "Introduction to Programming Nanodegree",
    notes: ["HTML, CSS, Python, JavaScript"],
    type: "education",
  },
  {
    date: "Sep 2018 - Dec 2019",
    link: "https://kaproductions.co.uk/",
    title: "K&A Productions Ltd.",
    subtitle: "Recording and Mastering Engineer",
    notes: ["Full-time"],
    type: "employment",
  },
  {
    date: "Sep 2014 - Jun 2018",
    link:
      "https://www.surrey.ac.uk/undergraduate/music-and-sound-recording-tonmeister",
    title: "University of Surrey",
    subtitle: "BSc Music and Sound Recording (Tonmeister)",
    notes: ["First-Class Honours"],
    type: "education",
  },
  {
    date: "Sep 2016 - Sep 2017",
    link: "https://www.maestromusicuk.com/",
    title: "Maestro Music UK",
    subtitle: "Production Assistant",
    notes: ["Industrial Placement Year"],
    type: "employment",
  },
  {
    date: "Sep 2007 - Jun 2014",
    link: "https://www.stpetersyork.org.uk/st_peters_13_18",
    title: "St. Peter's School, York",
    subtitle: "A Levels",
    notes: ["Mathematics A*, Further Mathematics A*, Music A, Physics A"],
    type: "education",
  },
];
