import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Burger from "./Burger";

class MyNavbar extends Component {
  state = {
    navbarOpen: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.closeNavbar);
    document.getElementById("contact-me").scrollTop -= 10;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.closeNavbar);
  }

  closeNavbar = () => {
    if (this.state.navbarOpen) {
      this.setState({
        navbarOpen: false,
      });
    }
  };

  onclickHamburger = () => {
    this.setState({
      navbarOpen: !this.state.navbarOpen,
    });
  };

  render() {
    const { navbarOpen } = this.state;
    return (
      <>
        <nav className={navbarOpen ? "nav open" : "nav"} id="navbar">
          <ul>
            <li>
              <Link smooth to="/#home" className="nav-main-link">
                Ivan Cheng
              </Link>
            </li>
          </ul>
          <div className="navbar-wrapper">
            <ul>
              <li>
                <Link smooth to="/#about-me" className="nav-link">
                  About me
                </Link>
              </li>
              <li>
                <Link smooth to="/#projects" className="nav-link">
                  Projects
                </Link>
              </li>
              <li>
                <Link smooth to="/#experience" className="nav-link">
                  Experience
                </Link>
              </li>
              <li>
                <Link smooth to="/#contact-me" className="nav-link">
                  Contact Me
                </Link>
              </li>
              <li>
                <a
                  href={process.env.PUBLIC_URL + "/Resume.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link resume"
                >
                  Resume
                </a>
              </li>
            </ul>
          </div>
          <Burger
            onclickHamburger={this.onclickHamburger}
            isOpen={this.state.navbarOpen}
          />
        </nav>
      </>
    );
  }
}

export default MyNavbar;
