import React, { Component } from "react";
import "../Privacy.css";

class Privacy extends Component {
  render() {
    return (
      <div className="App">
        <section className="Privacy-section">
          <h2>Privacy Policy</h2>
          <p>
            Ivan Cheng built the Collectors App as a Free app. This service is
            provided by Ivan Cheng at no cost and is intended for use as is.
            This page is used to inform visitors regarding my policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use my Service. If you choose to use my Service, then you
            agree to the collection and use of information in relation to this
            policy. The Personal Information that I collect is used for
            providing and improving the Service. I will not use or share your
            information with anyone except as described in this Privacy Policy.
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at Collectors App
            unless otherwise defined in this Privacy Policy.
          </p>
          <h4>Information Collection and Use</h4>
          <p>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information. The
            information that we request will be retained on your device and is
            not collected by me in any way. The app may use third party services
            that may collect information used to identify you. Please visit{" "}
            <a href="https://www.google.com/policies/privacy/">here</a> for
            Google Play Services privacy policy of third party service providers
            used by the app.
          </p>
          <p>
            We only ask for personal information when we truly need it to
            provide a service to you. We collect it by fair and lawful means,
            with your knowledge and consent. We also let you know why we’re
            collecting it and how it will be used.
          </p>
          <p>
            We only retain collected information for as long as necessary to
            provide you with your requested service. What data we store, we’ll
            protect within commercially acceptable means to prevent loss and
            theft, as well as unauthorised access, disclosure, copying, use or
            modification. We don’t share any personally identifying information
            publicly or with third-parties, except when required to by law.
          </p>
          <p>
            Our app may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot accept responsibility or
            liability for their respective privacy policies.
          </p>
          <p>
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </p>
          <p>
            Your continued use of our app will be regarded as acceptance of our
            practices around privacy and personal information. If you have any
            questions about how we handle user data and personal information,
            feel free to contact us.
          </p>
        </section>
      </div>
    );
  }
}

export default Privacy;
