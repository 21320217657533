import React, { Component } from "react";

class ProjectNavBtn extends Component {
  render() {
    const { activeTab, title, handleClick } = this.props;

    return (
      <li>
        <button
          type="button"
          onClick={handleClick}
          value={title}
          className={activeTab !== title ? "project-tab" : "project-tab active"}
        >
          {title}
        </button>
      </li>
    );
  }
}

export default ProjectNavBtn;
